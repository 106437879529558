<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">Dialog</v-btn>
    </template>
    <v-card class="no_style">
      <v-card-text>
        <InvoiceForm
          :invoice="invoice"
          @validated="save()"
          @cancel="dialog = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewInvoiceForm",
  components: {
    InvoiceForm: () => import("@/components/invoices/Form"),
  },
  data() {
    return {
      invoice: {
        type: "inventory",
        items: [],
        taxes: [],
        new_to_user: null,
        new_from_user: null,
      },
      dialog: false,
    };
  },
  methods: {
    ...mapActions("", []),
    generate(inventory_movement) {
      console.log("Fetching Inventory Movement");
      this.invoice.items = [
        {
          description: inventory_movement.inventory.product_cody,
          price:
            /* inventory_movement.cantidad > 0
            ? inventory_movement.inventory.product_price
            : */
            inventory_movement.price,
          quantity: Math.abs(inventory_movement.cantidad),
          reference_class: "inventorymovement",
          reference_id: inventory_movement.id,
        },
      ];

      if (inventory_movement.cantidad < 0) {
        this.$set(
          this.invoice,
          "from_user_id",
          this.$store.state.auth.user.studio.id
        );
        this.invoice.from_user_id = this.$store.state.auth.user.studio.id;
      } else {
        this.$set(
          this.invoice,
          "to_user_id",
          this.$store.state.auth.user.studio.id
        );
        this.invoice.to_user_id = this.$store.state.auth.user.studio.id;
      }
      console.log(inventory_movement);
      this.dialog = true;
    },
    ...mapActions("invoices", ["addInvoice"]),
    save() {
      this.addInvoice(this.invoice).then(() => {
        this.$alert(this.$t("save_ok"));
        this.dialog = false;
      });
    },
  },
};
</script>